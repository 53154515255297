import PropTypes from 'prop-types'

export const SCHOOL_TYPE = 'school'
export const PARTNER_TYPE = 'organization'
export const PARTNERSHIP_TYPE = 'partnership'
export const AND_TYPE = 'and'
export const OR_TYPE = 'or'
export const CERTIFICATION_EXCELLING = 'Excelling'
export const CERTIFICATION_STRONG = 'Strong'
export const CERTIFICATION_EMERGING = 'Emerging'
export const CERTIFICATION_DEVELOPING = 'Developing'
export const CERTIFICATION_INCOMPLETE = 'Incomplete'
export const CERTIFICATION_BADGE_TYPES = [
  CERTIFICATION_EXCELLING,
  CERTIFICATION_STRONG,
  CERTIFICATION_EMERGING,
  CERTIFICATION_DEVELOPING,
  CERTIFICATION_INCOMPLETE,
]
export const RELEVANCE_SORT_TYPE = '_score'
export const ALPHABETICAL_SORT_TYPE = 'sort_name.keyword'
export const NEWEST_SORT_TYPE = 'created_at'
export const OLDEST_SORT_TYPE = 'created_at_oldest'
export const LAST_ACTIVITY_SORT_TYPE = 'last_activity'
export const COMMENT_COUNT_SORT_TYPE = 'comment_count'

export const DEFAULT_POST_SORT_OPTIONS = [
  { key: 'Latest Activity', value: LAST_ACTIVITY_SORT_TYPE },
  { key: 'Newest', value: NEWEST_SORT_TYPE },
  { key: 'Oldest', value: OLDEST_SORT_TYPE },
  { key: 'Most Comments', value: COMMENT_COUNT_SORT_TYPE },
]
export const SEARCH_POST_SORT_OPTIONS = [
  { key: 'Relevance', value: RELEVANCE_SORT_TYPE },
  ...DEFAULT_POST_SORT_OPTIONS,
]

export const PLANNING_PHASE_TYPE = 'planning'
export const IMPLEMENTING_PHASE_TYPE = 'implementing'
export const REFLECTING_PHASE_TYPE = 'reflecting_and_revising'
export const LINK_EVIDENCE_TYPE = 'LinkEvidence'
export const FILE_EVIDENCE_TYPE = 'FileEvidence'
export const SCHOOL_SOURCE_TYPE = 'ArtsLiaison'
export const PARTNER_SOURCE_TYPE = 'ArtPartner'
export const MAP_SOURCE_TYPE = 'ArtLookMap'
export const HIGH_SCHOOL_TYPE = 'high_school'
export const MIDDLE_SCHOOL_TYPE = 'middle_school'
export const ELEMENTARY_SCHOOL_TYPE = 'elementary_school'
export const COMBO_SCHOOL_TYPE = 'combination'
export const ALL_SCHOOLS_TYPE = 'all_schools'
export const SCHOOL_TYPES = [
  ELEMENTARY_SCHOOL_TYPE,
  MIDDLE_SCHOOL_TYPE,
  HIGH_SCHOOL_TYPE,
  COMBO_SCHOOL_TYPE,
  ALL_SCHOOLS_TYPE,
]
export const MISSING_SURVEY_VALUE = 'Not Provided'
export const REQUEST_FORM_TYPE = 'request'
export const BOOKMARK_FILTER_NAME = 'bookmarked'

export const COMMENT_FORM_NAME = 'post-comment'
export const THREADED_COMMENT_FORM_NAME = 'comment-comment'
export const COMMENT_NEW_ID = 'comment-new'
export const COMMENT_CONTAINER_ID_PREFIX = 'comment-'
export const MENTIONED_USER_ID_PREFIX = 'mentioned-user-id-'

export const BLANK_VALUE = ' '

export const UNIQUE_LOCATIONS_KEY = 'uniqueLocations'

export const MODERATOR_ROLES = ['moderator', 'admin', 'super']

export const PORTAL = {
  SCHOOL_PORTAL: 'school portal',
  PARTNER_PORTAL: 'partner portal',
  EDUCATOR_PORTAL: 'educator portal',
}

export const SCHOOL_SUCCESS_MESSAGE = 'School information updated!'

export const SURVEY_PAGES = {
  STAFFING_AND_INSTRUCTION: 'staffing-and-instruction',
  COMMUNITY_AND_PARTNERSHIPS: 'community-and-partnerships',
  BUDGET_AND_PLANNING: 'budget-and-planning',
}

export const SURVEY_QUESTIONS_NECESSITIES = {
  REQUIRED: 'required',
  WARNING: 'warning',
  OPTIONAL: 'optional',
}

export const QUESTION_DISPLAY_COMPONENTS = {
  INSTRUCTIONAL_DEPTH_COURSE_FORM: 'InstructionalDepthCourseForm',
  WEEKLY_MINUTES_COURSE_FORM: 'WeeklyMinutesCourseForm',
  ARTS_INSTRUCTOR_FORM: 'ArtsInstructorForm',
  SCHOLARSHIPS_FORM: 'ScholarshipsForm',
  STANDARDS_FORM: 'StandardsForm',
  FUNDING_FORM: 'FundingForm',
  COURSE_SCHEDULE_FORM: 'CourseScheduleForm',
  CUSTOM_QUESTION_FORM: 'CustomQuestionForm',
}

export const SURVEY_QUESTIONS = {
  APPROACHES: {
    NAME: 'approaches',
    OPTIONS: {
      GENERAL: 'general',
      PER_DISCIPLINE: 'perDiscipline',
    },
  },
  INTEGRATIONS: {
    NAME: 'integrations',
    OPTIONS: {
      GENERAL: 'general',
    },
  },
  GOVERNANCE: {
    NAME: 'governance',
    OPTIONS: {
      GENERAL: 'general',
    },
  },
  OBSTACLES_SELECT: {
    NAME: 'obstaclesSelect',
    OPTIONS: {
      GENERAL: 'general',
    },
  },
  OBSTACLES_DESCRIBE: {
    NAME: 'obstaclesDescribe',
    OPTIONS: {
      GENERAL: 'general',
    },
  },
  OBSTACLES_OVERCOME: {
    NAME: 'obstaclesOvercome',
    OPTIONS: {
      GENERAL: 'general',
    },
  },
  DISTRICT_SPENDING: {
    NAME: 'districtSpending',
    OPTIONS: {
      GENERAL: 'general',
    },
  },
  COMMUNITY_ENGAGEMENTS: {
    NAME: 'communityEngagement',
    OPTIONS: {
      GENERAL: 'general',
    },
  },
}

export const SCHOOL_YEAR_STATES = {
  PUBLISHED: 'published',
}

export const COURSE_STATES = {
  APPROVED: 'approved',
  RESPONDENT_SAVED: 'respondent_saved',
}

export const POST_STATES = {
  ACTIVE: 'active',
  HIDDEN: 'hidden',
  LOCKED: 'locked',
  ARCHIVED: 'archived',
}

export const EMPLOYABLE_TYPES = {
  ORGANIZATION: 'Organization',
  SCHOOL: 'School',
}

export const DISCIPLINE_TYPES = {
  DANCE: 'dance',
  LITERARY: 'literary_arts',
  MEDIA: 'media_arts',
  MUSIC: 'music',
  VISUAL: 'visual_arts',
  THEATRE: 'theatre',
  OTHER: 'other',
  NONE: 'none',
}

export const SUB_DISCIPLINE_OFFER_TYPES = {
  IN_SCHOOL: 'in_school',
  OUT_OF_SCHOOL: 'out_of_school',
  BOTH: 'both',
  NONE: 'none',
}

export const PROGRAM_TYPES = {
  RESIDENCY: 'residency',
  OUT_OF_SCHOOL_TIME: 'out_of_school_time',
  FIELD_TRIP: 'field_trip',
  IN_SCHOOL_PERFORMANCE: 'in_school_performance',
  RESOURCES: 'resources',
  PROFESSIONAL_DEVELOPMENT: 'professional_development',
  ONLINE: 'online',
}

export const RESOURCE_TYPES = {
  SUPPLIES_AND_EQUIPMENT: 'supplies_and_equipment',
  DEDICATED_ARTS_BUDGET: 'dedicated_arts_budget',
  ADDITIONAL_STAFFING: 'additional_arts_staffing',
  MENTORSHIP_EXCHANGE: 'mentorship_exchange',
  INTEGRATION: 'arts_integration',
  CURRICULUM_ASSISTANCE: 'curriculum_assistance',
  SPECIFIC_INTEREST: 'specific_interest',
  PROFESSIONAL_DEVELOPMENT: 'pd_non_arts',
  PROFESSIONAL_DEVELOPMENT_ARTS: 'pd_arts',
}

export const NOTIFICATIONABLE_TYPES = {
  EMPLOYEE: 'Employee',
  POST: 'Post',
  COMMENT: 'Comment',
  ATTACHMENT: 'Attachment',
  ANNOUNCEMENT: 'Announcement',
  LIKE: 'Like',
}

export const DISPLAYED_ARTS_PROGRAMMING_RESOURCES = {
  ...DISCIPLINE_TYPES,
  ...PROGRAM_TYPES,
  ...RESOURCE_TYPES,
}

export const POSITION_TYPES = {
  EXECUTIVE_DIRECTOR: 'executive_director',
  EDUCATION_CONTACT: 'education_contact',
  EMPLOYEE: 'employee',
  PRINCIPAL: 'principal',
  ARTS_LIAISON: 'arts_liaison',
  TEACHER: 'teacher',
}

export const EDUCATOR_POSITIONS = [
  POSITION_TYPES.ARTS_LIAISON,
  POSITION_TYPES.TEACHER,
]

export const MAP_POSITIONS = [
  POSITION_TYPES.EXECUTIVE_DIRECTOR,
  POSITION_TYPES.EDUCATION_CONTACT,
  POSITION_TYPES.EMPLOYEE,
  POSITION_TYPES.PRINCIPAL,
  POSITION_TYPES.ARTS_LIAISON,
]

export const SUBSCRIPTION_OPTIONS = {
  NEW_PARTNERSHIPS: {
    NAME: 'newPartnerships',
    LABEL: 'New Partnerships',
  },
}

export const OBSTACLES = {
  ADAPTING_CURRICULUM: 'adapting_curriculum',
  ADMIN_LEVEL_SUPPORT: 'admin_level_support',
  ASSESSING_STUDENTS: 'assessing_students',
  COMMUNICATING_WITH_FAMILIES: 'communicating_with_families',
  CREATING_CURRICULUM: 'creating_curriculum',
  DISTRICT_LEVEL_SUPPORT: 'district_level_support',
  FAMILY_SUPPORT: 'family_support',
  PERSONAL_ACCESS_TO_TECH: 'personal_access_to_tech',
  STUDENT_ACCESS_TO_MATERIALS: 'student_access_to_materials',
  STUDENT_ACCESS_TO_TECH: 'student_access_to_tech',
  STUDENT_ATTENDANCE: 'student_attendance',
  STUDENT_ENGAGEMENT: 'student_engagement',
  STUDENT_FOLLOW_THROUGH: 'student_follow_through',
  TEACHER_FAMILIARITY: 'teacher_familiarity',
  OTHER: 'other',
  ADDITIONAL_INFORMATION: 'additional_information',
  WHAT_WORKED_WELL: 'what_worked_well',
}

// These obstacles are not included in a table format
export const FREE_TEXT_QUESTION_OBSTACLES = [
  OBSTACLES.ADDITIONAL_INFORMATION,
  OBSTACLES.WHAT_WORKED_WELL,
]

export const LEADERSHIP_CHARACTERISTIC_TAGS = {
  BIPOC: 'bipoc',
}

export const BOOKMARKABLE = {
  USER: 'User',
  POST: 'Post',
}

export const COMMENTABLE = {
  POST: 'Post',
  COMMENT: 'Comment',
}

export const REVIEWABLE = {
  POST: 'Post',
  COMMENT: 'Comment',
  PROFILE: 'Profile',
}

export const LIKEABLE = {
  POST: 'Post',
  COMMENT: 'Comment',
}

export const HIGHLIGHT_EXCERPT_NAMES = {
  PROGRAM_DESCRIPTIONS: 'programDescriptions',
  MISSION: 'mission',
}

export const SEARCH_OPTIONS_TYPE = {
  MAP: 'map',
  DIRECTORY: 'directory',
  FORUM: 'forum',
}

export const FLAG_RESOLUTION_NOTES = {
  AGREE: 'I agree this is inappropriate.',
  DISAGREE: 'I disagree with this being inappropriate.',
}

export const FOLDERABLE_TYPE = {
  COMMUNITY_OF_PRACTICE: 'CommunityOfPractice',
  FOLDER: 'Folder',
}

export const EMAIL_FREQUENCY = {
  IMMEDIATE: 'immediate',
  DAILY_DIGEST: 'daily',
  WEEKLY_DIGEST: 'weekly',
  NEVER: 'never',
}

// ----- Shapes -----

export const sortType = PropTypes.oneOf([
  RELEVANCE_SORT_TYPE,
  ALPHABETICAL_SORT_TYPE,
  NEWEST_SORT_TYPE,
  OLDEST_SORT_TYPE,
  LAST_ACTIVITY_SORT_TYPE,
  COMMENT_COUNT_SORT_TYPE,
])

export const ref = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
])

export const address = PropTypes.shape({
  id: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  street: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  zip: PropTypes.string.isRequired,
  longitude: PropTypes.string,
  latitude: PropTypes.string,
  city: PropTypes.string.isRequired,
})

export const schoolSubDiscipline = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string.isRequired,
  schoolYearId: PropTypes.number,
  tag: PropTypes.string.isRequired,
  subDisciplineId: PropTypes.number.isRequired,
  disciplineId: PropTypes.number,
  displayName: PropTypes.string,
})

export const subDiscipline = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
})

export const discipline = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  subDisciplines: PropTypes.arrayOf(subDiscipline),
})

export const accessiblePrograms = PropTypes.objectOf(schoolSubDiscipline)

export const accessibleProgramsByDiscipline =
  PropTypes.objectOf(accessiblePrograms)

export const sourceType = PropTypes.oneOf([
  SCHOOL_SOURCE_TYPE,
  PARTNER_SOURCE_TYPE,
  MAP_SOURCE_TYPE,
])

export const position = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
})

export const contact = PropTypes.shape({
  id: PropTypes.number.isRequired,
  fullName: PropTypes.string.isRequired,
  title: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
})

export const instructor = PropTypes.shape({
  id: PropTypes.number.isRequired,
  focuses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      type: PropTypes.string.isRequired,
      primary: PropTypes.bool.isRequired,
      instructorId: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  fullTimeEquivalent: PropTypes.string,
  middleInitial: PropTypes.string,
  schoolYearId: PropTypes.number.isRequired,
  displayName: PropTypes.string.isRequired,
  nonArts: PropTypes.bool.isRequired,
})

export const course = PropTypes.shape({
  id: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  classNumber: PropTypes.string.isRequired,
  averageMinutesPerWeek: PropTypes.number,
  instructionalDepth: PropTypes.string,
})

export const coursesBySchoolGrade = PropTypes.objectOf(
  PropTypes.arrayOf(course)
)

export const courseDisciplines = PropTypes.shape({
  coursesData: PropTypes.arrayOf(course),
  averageMinutesPerWeek: PropTypes.number,
  totalEnrollment: PropTypes.number.isRequired,
})

export const courseInformation = PropTypes.objectOf(courseDisciplines)

export const artsLiaison = PropTypes.shape({
  email: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
})

export const program = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  primaryOutcomes: PropTypes.arrayOf(PropTypes.string),
  secondaryOutcomes: PropTypes.arrayOf(PropTypes.string),
  programContentTypes: PropTypes.arrayOf(PropTypes.string),
})

export const nestedPartner = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  address: address.isRequired,
  longitude: PropTypes.number.isRequired,
  latitude: PropTypes.number.isRequired,
  programs: PropTypes.arrayOf(PropTypes.string).isRequired,
})

export const partnership = PropTypes.shape({
  id: PropTypes.number.isRequired,
  organizationName: PropTypes.string,
  organizationId: PropTypes.number,
  schoolId: PropTypes.number.isRequired,
  schoolName: PropTypes.string,
  schoolYearId: PropTypes.number.isRequired,
  contentTypes: PropTypes.arrayOf(PropTypes.string),
})

export const partner = PropTypes.shape({
  id: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  address: address.isRequired,
  detail: PropTypes.shape({
    description: PropTypes.string,
    schoolYearId: PropTypes.number.isRequired,
    website: PropTypes.string.isRequired,
  }),
  partnerships: PropTypes.arrayOf(partnership).isRequired,
  disciplines: PropTypes.arrayOf(discipline),
  programs: PropTypes.arrayOf(PropTypes.oneOfType([program, PropTypes.string]))
    .isRequired,
  logo: image,
})

export const certification = PropTypes.shape({
  level: PropTypes.oneOf(CERTIFICATION_BADGE_TYPES).isRequired,
  description: PropTypes.string.isRequired,
})

export const detail = PropTypes.shape({
  id: PropTypes.number.isRequired,
  billingAccount: PropTypes.string,
  cscRating: PropTypes.string,
  description: PropTypes.string,
  detailableId: PropTypes.number.isRequired,
  detailableType: PropTypes.string.isRequired,
  fax: PropTypes.string,
  governance: PropTypes.string,
  ncesIdentifier: PropTypes.string,
  phone: PropTypes.string,
  professionalDevelopment: PropTypes.bool,
  scheduleUrl: PropTypes.string,
  schoolYearId: PropTypes.number.isRequired,
  servicesRoute: PropTypes.string,
  stateBoardIdentifier: PropTypes.string,
  website: PropTypes.string,
})

export const expandedContent = PropTypes.shape({
  excelling: PropTypes.shape({
    label: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
  }),
  strong: PropTypes.shape({
    label: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
  }),
  developing: PropTypes.shape({
    label: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
  }),
  emerging: PropTypes.shape({
    label: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
  }),
  incomplete: PropTypes.shape({
    label: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
  }),
  note: PropTypes.string,
})

export const metricCategory = PropTypes.shape({
  id: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  rating: PropTypes.string,
  displayComponent: PropTypes.string.isRequired,
  row: PropTypes.number.isRequired,
  column: PropTypes.number.isRequired,
  expandedContent: expandedContent,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
})

export const metricScore = PropTypes.shape({
  id: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  preliminaryScore: PropTypes.number,
  finalScore: PropTypes.number.isRequired,
  showFinalScore: PropTypes.bool.isRequired,
  pdfUrl: PropTypes.string,
  level: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  schoolYearId: PropTypes.number.isRequired,
  categories: PropTypes.arrayOf(metricCategory).isRequired,
})

export const school = PropTypes.shape({
  id: PropTypes.number.isRequired,
  displayName: PropTypes.string.isRequired, // will include school's city for communities that enable that option
  metricScore: metricScore,
  name: PropTypes.string.isRequired,
  instructors: PropTypes.arrayOf(instructor),
  address: address,
  phoneNumber: PropTypes.string,
  partners: PropTypes.arrayOf(nestedPartner),
  openToPartnerships: PropTypes.bool,
})

export const image = PropTypes.shape({
  src: PropTypes.string.isRequired,
  alt: PropTypes.string, // use "" for presentational images
  filename: PropTypes.string,
  category: PropTypes.string,
  link: PropTypes.string,
})

export const attachment = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  uploadUrl: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  attachableId: PropTypes.number.isRequired,
  attachableType: PropTypes.string.isRequired,
})

export const attachmentUpload = PropTypes.shape({
  src: PropTypes.string.isRequired,
  filename: PropTypes.string.isRequired,
})

export const district = PropTypes.shape({
  id: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  isAnyGivenChild: PropTypes.bool.isRequired,
  logo: image,
  sponsorLogo: image,
})

export const schoolCompletionStatus = PropTypes.shape({
  id: PropTypes.number.isRequired,
  schoolId: PropTypes.number.isRequired,
  feedback: PropTypes.string,
})

export const schoolSearchData = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  address: PropTypes.object,
  disciplines: PropTypes.arrayOf(PropTypes.string),
  partnerCount: PropTypes.number,
  openToPartnerships: PropTypes.bool,
})

// Stubbed for now
export const partnerSearchData = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  address: address,
  disciplines: PropTypes.arrayOf(PropTypes.object).isRequired,
  programs: PropTypes.arrayOf(program),
  openToPartnerships: PropTypes.bool,
})

export const searchResultType = PropTypes.oneOf([
  SCHOOL_TYPE,
  PARTNER_TYPE,
  `${SCHOOL_TYPE}_search`,
  `${PARTNER_TYPE}_search`,
])

export const searchResult = PropTypes.shape({
  type: searchResultType.isRequired,
  data: PropTypes.oneOfType([schoolSearchData, partnerSearchData]),
})

export const searchSuggestion = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  name: PropTypes.string.isRequired,
})

export const location = PropTypes.shape({
  type: PropTypes.string.isRequired,
  latitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  longitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
})

export const searchFilters = PropTypes.any

export const filterWithOperator = PropTypes.shape({
  values: PropTypes.oneOf([
    PropTypes.arrayOf(PropTypes.string),
    locationFilter,
  ]),
  operator: PropTypes.oneOf([AND_TYPE, OR_TYPE]),
})

export const locationFilter = PropTypes.shape({
  networks: PropTypes.arrayOf(PropTypes.string),
  zipCodes: PropTypes.arrayOf(PropTypes.string),
  wards: PropTypes.arrayOf(PropTypes.string),
  communityAreas: PropTypes.arrayOf(PropTypes.string),
})

export const element = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  order: PropTypes.number.isRequired,
  description: PropTypes.string,
})

export const evidence = PropTypes.shape({
  id: PropTypes.number.isRequired,
  type: PropTypes.oneOf([LINK_EVIDENCE_TYPE, FILE_EVIDENCE_TYPE]),
  name: PropTypes.string.isRequired,
  uploadedAt: PropTypes.string.isRequired,
  url: PropTypes.string,
  fileUrl: PropTypes.string,
})

export const phase = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
})

export const tool = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  fileUrl: PropTypes.string.isRequired,
})

export const component = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
})

export const framework = PropTypes.shape({
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
})

export const formOptionType = PropTypes.shape({
  key: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  displayKey: PropTypes.string,
})

export const schoolRubric = PropTypes.shape({
  certification: certification.isRequired,
  preliminaryScore: PropTypes.number.isRequired,
  finalScore: PropTypes.number.isRequired,
  fte: PropTypes.number.isRequired,
  minutes: PropTypes.number.isRequired,
  disciplines: PropTypes.number.isRequired,
  hasBudget: PropTypes.bool.isRequired,
  hasProfDev: PropTypes.bool.isRequired,
  hasIntegration: PropTypes.bool.isRequired,
  hasPartners: PropTypes.bool.isRequired,
  hasEngagement: PropTypes.bool.isRequired,
})

export const completionStatus = PropTypes.shape({
  id: PropTypes.number.isRequired,
  budget: PropTypes.bool.isRequired,
  staffing: PropTypes.bool.isRequired,
  communityPartnership: PropTypes.bool.isRequired,
  missingFields: PropTypes.arrayOf(PropTypes.string).isRequired,
})

export const spaceType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
})

export const resourceType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
})

export const schoolIntegration = PropTypes.shape({
  id: PropTypes.number.isRequired,
  integrationTypeId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  otherText: PropTypes.string,
  schoolId: PropTypes.number.isRequired,
  schoolYearId: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
})

export const integrationType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
})

export const scheduleType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
})

export const schoolApproach = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  schoolId: PropTypes.number.isRequired,
  schoolYearId: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  secondaryResourceable: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }),
})

export const schoolGovernance = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  schoolId: PropTypes.number.isRequired,
  schoolYearId: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  secondaryResourceable: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }),
})

export const approach = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  definition: PropTypes.string,
})

export const schoolObstacle = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  schoolId: PropTypes.number.isRequired,
  schoolYearId: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  otherText: PropTypes.string,
  secondaryResourceable: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }),
})

export const surveyAnswer = PropTypes.shape({
  id: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  schoolId: PropTypes.number.isRequired,
  schoolYearId: PropTypes.number.isRequired,
  otherText: PropTypes.string.isRequired,
  surveyQuestionId: PropTypes.number.isRequired,
})

export const obstacle = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  definition: PropTypes.string,
})

export const communityEnumerable = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  definition: PropTypes.string,
})

export const schoolYear = PropTypes.shape({
  id: PropTypes.number.isRequired,
  number: PropTypes.string.isRequired,
  current: PropTypes.bool.isRequired,
})

export const programType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
})

export const attachableOption = PropTypes.shape({
  id: PropTypes.number.isRequired,
  key: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  communityOfPracticeId: PropTypes.number.isRequired,
})

export const folderableOption = PropTypes.shape({
  id: PropTypes.number.isRequired,
  key: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  communityOfPracticeId: PropTypes.number.isRequired,
})

export const partnerPartnershipType = PropTypes.shape({
  schoolName: PropTypes.string.isRequired,
  schoolId: PropTypes.number.isRequired,
  programTypes: PropTypes.arrayOf(
    PropTypes.shape({
      programTypeId: PropTypes.number.isRequired,
    })
  ).isRequired,
})

export const schoolPartnershipType = PropTypes.shape({
  organizationName: PropTypes.string.isRequired,
  organizationId: PropTypes.number.isRequired,
  programTypes: PropTypes.arrayOf(
    PropTypes.shape({
      programTypeId: PropTypes.number.isRequired,
    })
  ).isRequired,
})

export const outcomeType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
})

export const engagementType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
})

export const grade = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
})

export const communityConfig = PropTypes.object

export const requestedInterest = PropTypes.shape({
  id: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  schoolYearId: PropTypes.number.isRequired,
  schoolId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
})

export const step = PropTypes.shape({
  name: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
})

export const portalType = PropTypes.oneOf([
  PORTAL.SCHOOL_PORTAL,
  PORTAL.PARTNER_PORTAL,
  PORTAL.EDUCATOR_PORTAL,
])

export const surveyQuestionConfigOption = PropTypes.shape({
  question: PropTypes.string.isRequired,
  error: PropTypes.string,
  format: PropTypes.string.isRequired,
  responseType: PropTypes.string.isRequired,
})

export const surveyQuestionConfig = PropTypes.objectOf(
  surveyQuestionConfigOption
)

export const survey = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  schoolYearId: PropTypes.number.isRequired,
  dueDate: PropTypes.string.isRequired,
})

export const surveyQuestion = PropTypes.shape({
  id: PropTypes.number.isRequired,
  body: PropTypes.string.isRequired,
  description: PropTypes.string,
  surveyPage: PropTypes.string.isRequired,
  position: PropTypes.number.isRequired,
  displayComponent: PropTypes.string.isRequired,
  readOnly: PropTypes.bool.isRequired,
  necessity: PropTypes.string.isRequired,
})

export const surveyAttemptReview = PropTypes.shape({
  id: PropTypes.number.isRequired,
  coursesWithNoInstructors: PropTypes.bool.isRequired,
  instructorsWithNoCourses: PropTypes.bool.isRequired,
})

export const surveyAttempt = PropTypes.shape({
  id: PropTypes.number.isRequired,
  state: PropTypes.string.isRequired,
  feedback: PropTypes.string,
  surveyQuestions: PropTypes.arrayOf(surveyQuestion),
  review: surveyAttemptReview,
  survey,
})

export const participation = PropTypes.shape({
  courseEnrollment: PropTypes.number.isRequired,
  coursePercentAccess: PropTypes.string.isRequired,
  enrollment: PropTypes.number.isRequired,
  enrollmentRatio: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  schoolId: PropTypes.number.isRequired,
  schoolYearId: PropTypes.number.isRequired,
  selfReportedEnrollment: PropTypes.number.isRequired,
})

export const employer = PropTypes.shape({
  id: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  employableType: PropTypes.string.isRequired,
  employable: PropTypes.object.isRequired,
  position: PropTypes.string,
})

export const applicableGrade = PropTypes.shape({
  id: PropTypes.number.isRequired,
  gradeableId: PropTypes.number.isRequired,
  gradeableType: PropTypes.string.isRequired,
  gradeId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  position: PropTypes.number.isRequired,
})

export const membership = PropTypes.shape({
  id: PropTypes.number.isRequired,
  communityOfPracticeId: PropTypes.number.isRequired,
})

export const profile = PropTypes.shape({
  id: PropTypes.number.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  middleInitial: PropTypes.string,
  avatarUrl: PropTypes.string,
  pronouns: PropTypes.string,
  yearsOfExperience: PropTypes.number,
  disciplines: PropTypes.arrayOf(discipline),
  grades: PropTypes.arrayOf(applicableGrade),
  memberships: PropTypes.arrayOf(membership),
})

export const user = PropTypes.shape({
  email: PropTypes.string.isRequired,
  employments: PropTypes.arrayOf(employer).isRequired,
  id: PropTypes.number.isRequired,
  role: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  agreedAt: PropTypes.string,
  notificationSubscriptionId: PropTypes.number.isRequired,
  profile,
})

export const notificationPreferences = PropTypes.shape({
  id: PropTypes.number.isRequired,
  emailFrequency: PropTypes.string.isRequired,
  employeeAccessChange: PropTypes.bool.isRequired,
  communityOfPracticePosts: PropTypes.bool.isRequired,
  communityOfPracticeComments: PropTypes.bool.isRequired,
  communityOfPracticeAttachments: PropTypes.bool.isRequired,
  communityOfPracticeAnnouncements: PropTypes.bool.isRequired,
  postOrCommentLikes: PropTypes.bool.isRequired,
  postOrCommentMentions: PropTypes.bool.isRequired,
  commentsOnOwnOrCommentedPosts: PropTypes.bool.isRequired,
  commentsOnBookmarkedPosts: PropTypes.bool.isRequired,
  commentsOnInterestedPosts: PropTypes.bool.isRequired,
  partnershipMatches: PropTypes.bool.isRequired,
  newPartnerships: PropTypes.bool.isRequired,
  authorizedSubscriptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  postCategories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      schoolCategoryId: PropTypes.number.isRequired,
    })
  ),
  postDisciplines: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      disciplineId: PropTypes.number.isRequired,
    })
  ),
})

export const notification = PropTypes.shape({
  id: PropTypes.number.isRequired,
  content: PropTypes.string.isRequired,
  linkUrl: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  isUnread: PropTypes.bool.isRequired,
  notificationIds: PropTypes.arrayOf(PropTypes.number).isRequired,
})

export const flag = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  reason: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
  reviewableType: PropTypes.string,
  reviewableId: PropTypes.number,
  profileName: PropTypes.string,
  userId: PropTypes.number,
  resolutionNote: PropTypes.string,
  reviewerId: PropTypes.number,
})

export const directoryUser = PropTypes.shape({
  id: PropTypes.number.isRequired,
  profileId: PropTypes.number,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  schoolNames: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  neighborhoods: PropTypes.arrayOf(PropTypes.string),
  avatarUrl: PropTypes.string,
  disciplineIds: PropTypes.arrayOf(PropTypes.number),
  disciplineNames: PropTypes.arrayOf(PropTypes.string),
  gradeIds: PropTypes.arrayOf(PropTypes.number),
  gradeNames: PropTypes.arrayOf(PropTypes.string),
  yearsOfExperience: PropTypes.number,
  pronouns: PropTypes.string,
  flags: PropTypes.arrayOf(flag).isRequired,
})

export const directoryFilterOptions = PropTypes.shape({
  disciplines: PropTypes.arrayOf(formOptionType).isRequired,
  gradesServed: PropTypes.arrayOf(formOptionType).isRequired,
  neighborhoods: PropTypes.arrayOf(formOptionType),
})

export const directoryFilterSelections = PropTypes.shape({
  disciplineIds: PropTypes.arrayOf(PropTypes.number),
  gradeIds: PropTypes.arrayOf(PropTypes.number),
  neighborhood: PropTypes.arrayOf(PropTypes.string),
})

export const bookmark = PropTypes.shape({
  id: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
  bookmarkableId: PropTypes.number.isRequired,
  bookmarkableType: PropTypes.string.isRequired,
})

export const topicTag = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
})

export const forumUser = PropTypes.shape({
  id: PropTypes.number.isRequired,
  schoolNames: PropTypes.string.isRequired,
  name: PropTypes.string,
  profileImgUrl: PropTypes.string,
  pronouns: PropTypes.string,
  flags: PropTypes.arrayOf(flag),
})

const commentDetails = {
  id: PropTypes.number.isRequired,
  content: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  user: forumUser.isRequired,
  commentableType: PropTypes.string.isRequired,
  commentableId: PropTypes.number.isRequired,
  flags: PropTypes.arrayOf(flag).isRequired,
  attachments: PropTypes.arrayOf(attachment),
}

export const comment = PropTypes.shape({
  ...commentDetails,
  threadedComments: PropTypes.arrayOf(PropTypes.shape(commentDetails)),
  threadedCommentsCount: PropTypes.number.isRequired,
})

export const post = PropTypes.shape({
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  disciplines: PropTypes.arrayOf(discipline).isRequired,
  topics: PropTypes.arrayOf(topicTag).isRequired,
  commentsCount: PropTypes.number,
  createdAt: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  user: forumUser.isRequired,
  lastActiveAt: PropTypes.string,
  comments: PropTypes.arrayOf(comment),
  flags: PropTypes.arrayOf(flag).isRequired,
  communityOfPracticeId: PropTypes.number,
  attachments: PropTypes.arrayOf(attachment),
})

export const menuAction = PropTypes.shape({
  label: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  icon: PropTypes.func,
  actionProps: PropTypes.object,
})

export const postsFilterOptions = PropTypes.shape({
  disciplines: PropTypes.arrayOf(formOptionType).isRequired,
  schoolTypes: PropTypes.arrayOf(formOptionType).isRequired,
  schools: PropTypes.arrayOf(formOptionType).isRequired,
  topics: PropTypes.arrayOf(formOptionType).isRequired,
  users: PropTypes.arrayOf(formOptionType).isRequired,
})

export const flaggedItem = PropTypes.shape({
  flags: PropTypes.arrayOf(flag).isRequired,
  flagsCount: PropTypes.number.isRequired,
  reviewableType: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
})

export const flaggedItemsWithDate = PropTypes.shape({
  date: PropTypes.string.isRequired,
  flaggedItems: PropTypes.arrayOf(flaggedItem).isRequired,
})

export const communityOfPractice = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  lastActivityDate: PropTypes.string.isRequired,
  attachments: PropTypes.arrayOf(attachment).isRequired,
  attachmentsCount: PropTypes.number.isRequired,
})

export const announcement = PropTypes.shape({
  id: PropTypes.number.isRequired,
  content: PropTypes.string.isRequired,
  expiresAt: PropTypes.string.isRequired,
  communityOfPracticeId: PropTypes.number.isRequired,
})

const folderBasics = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  folderableId: PropTypes.number.isRequired,
}

export const indexFolder = PropTypes.shape({
  ...folderBasics,
})

export const showFolder = PropTypes.shape({
  ...folderBasics,
  allowNestedFolder: PropTypes.bool.isRequired,
  folderableType: PropTypes.string.isRequired,
  folderableName: PropTypes.string.isRequired,
  folders: PropTypes.arrayOf(indexFolder).isRequired,
  attachments: PropTypes.arrayOf(attachment).isRequired,
})

export const partnershipQuestion = PropTypes.shape({
  label: PropTypes.string.isRequired,
  description: PropTypes.string,
})

export const input = PropTypes.shape({
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number,
  ]),
})

export const scholarship = PropTypes.shape({
  amountCents: PropTypes.number.isRequired,
  studentsCount: PropTypes.number.isRequired,
  disciplineId: PropTypes.number.isRequired,
  schoolId: PropTypes.number.isRequired,
  schoolYearId: PropTypes.number.isRequired,
})

export const mentionSuggestion = PropTypes.shape({
  text: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
})

export const COMMUNITIES_WITH_TRANSLATION = ['miami']
